import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/design/design/node_modules/@primer/gatsby-theme-doctocat/src/components/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`This guide will show you how to upgrade from the now deprecated
`}<inlineCode parentName="p">{`Primer::TimeAgoComponent`}</inlineCode>{` to the latest `}<a parentName="p" {...{
        "href": "/components/relative-time/rails"
      }}><inlineCode parentName="a">{`Primer::Beta::RelativeTime`}</inlineCode></a>{`
component.`}</p>
    <h2>{`A Migration Example`}</h2>
    <p>{`Use of the `}<inlineCode parentName="p">{`TimeAgoComponent`}</inlineCode>{` component can be migrated with only a few minor changes.`}</p>
    <p>{`For example, if the `}<inlineCode parentName="p">{`TimeAgoComponent`}</inlineCode>{` was set up in this way:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-rb"
      }}>{`<%= render(Primer::TimeAgoComponent.new(time: Time.at(628232400))) %>
`}</code></pre>
    <p>{`It can be migrated by renaming `}<inlineCode parentName="p">{`time`}</inlineCode>{` to `}<inlineCode parentName="p">{`datetime`}</inlineCode>{`, and adding the `}<inlineCode parentName="p">{`tense:
:past`}</inlineCode>{` setting.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-rb"
      }}>{`<%= render(Primer::Beta::RelativeTime.new(datetime: Time.at(628232400), tense: :past)) %>
`}</code></pre>
    <h2>{`Arguments`}</h2>
    <p>{`The majority of options available in `}<inlineCode parentName="p">{`RelativeTime`}</inlineCode>{` are not relevant when
using it as a replacement for `}<inlineCode parentName="p">{`TimeAgo`}</inlineCode>{`. There are a few changes that need to be
noted, however.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`From `}<inlineCode parentName="th">{`Primer::TimeAgoComponent`}</inlineCode></th>
          <th parentName="tr" {...{
            "align": null
          }}>{`To `}<inlineCode parentName="th">{`Primer::Beta::RelativeTime`}</inlineCode></th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Notes`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`time`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`datetime`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Renamed argument, but the semantics remain the same`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`micro`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`format: :micro`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Instead of a boolean flag, set the `}<inlineCode parentName="td">{`format`}</inlineCode>{` argument to the value of `}<inlineCode parentName="td">{`:micro`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`n/a`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`tense: :past`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Required for displaying how long ago the set time was. This argument tells `}<inlineCode parentName="td">{`RelativeTime`}</inlineCode>{` to behave like `}<inlineCode parentName="td">{`TimeAgo`}</inlineCode>{` did.`}</td>
        </tr>
      </tbody>
    </table>
    <p>{`The remaining arguments for `}<inlineCode parentName="p">{`RelativeTime`}</inlineCode>{` can be found in the documentation for
that component.`}</p>
    <p>{`Please see the following for complete descriptions and examples.`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/components/relative-time/rails"
        }}><inlineCode parentName="a">{`Primer::Beta::RelativeTime`}</inlineCode>{` component`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://primer.style/view-components/lookbook/inspect/primer/beta/relative_time_preview/default"
        }}><inlineCode parentName="a">{`Primer::Beta::RelativeTime`}</inlineCode>{` Lookbook examples`}</a></li>
    </ul>
    <p>&nbsp;</p>
    <p><a parentName="p" {...{
        "href": "/guides/development/rails#upgrading-deprecated-components"
      }}>{`←`}{` Back to development docs`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      